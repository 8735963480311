import React, { Fragment, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { t } from 'i18next';
import { useCookies } from 'react-cookie';
import { useRecoilValue } from 'recoil';
import { LogIn, User, Layers, X } from 'react-feather';
import { Row, Col, Container } from 'reactstrap';

import {tokenState} from '../../recoil/recoil';

const Rightbar = () => {
    const navigate = useNavigate();
    const tokenData = useRecoilValue(tokenState);
    const [cookies, setCookie, removeCookie] = useCookies(['token']); // eslint-disable-line

    const [megaboxtoggle1, setMegaboxtoggle1] = useState(true)
    const [sideMenuOpen, setSideMenuOpen] = useState(false)

    const signOut = () => removeCookie('token', { path: '/' })

    let links = useMemo(() => {
        if (tokenData.parsedToken.type === 'accountant') {
            return [
                {url: `${process.env.PUBLIC_URL}/clients`, title: t('header.clients')},
                {url: `${process.env.PUBLIC_URL}/facilities`, title: t('header.facilities')},
                {url: `${process.env.PUBLIC_URL}/contracts`, title: t('header.contracts')},
                {url: `${process.env.PUBLIC_URL}/prices`, title: t('header.prices')},
                {url: `${process.env.PUBLIC_URL}/archive`, title: t('header.archive')},
            ]
        } else if (tokenData.parsedToken.type === 'admin' || tokenData.parsedToken.type === 'moderator') {
            return [
                {url: `${process.env.PUBLIC_URL}/clients`, title: t('header.clients')},
                {url: `${process.env.PUBLIC_URL}/facilities`, title: t('header.facilities')},
                {url: `${process.env.PUBLIC_URL}/contracts`, title: t('header.contracts')},
                {url: `${process.env.PUBLIC_URL}/security-systems`, title: t('header.systems')},
                {url: `${process.env.PUBLIC_URL}/prices`, title: t('header.prices')},
                {url: `${process.env.PUBLIC_URL}/users/true`, title: t('header.users')},
                {url: `${process.env.PUBLIC_URL}/schedule/users`, title: t('header.schedule')},
                {url: `${process.env.PUBLIC_URL}/archive`, title: t('header.archive')},
                {url: `${process.env.PUBLIC_URL}/mail`, title: t('header.mail')},
                {url: `${process.env.PUBLIC_URL}/statistic`, title: t('header.statistic')},
                {url: `${process.env.PUBLIC_URL}/projects`, title: t('header.projects')},
                {url: `${process.env.PUBLIC_URL}/phonebook`, title: t('header.phonebook')},
            ]
        } else if (tokenData.parsedToken.type === 'technician') {
            return [
                {url: `${process.env.PUBLIC_URL}/clients`, title: t('header.clients')},
                {url: `${process.env.PUBLIC_URL}/facilities`, title: t('header.facilities')},
                {url: `${process.env.PUBLIC_URL}/schedule/users`, title: t('header.schedule')},
                {url: `${process.env.PUBLIC_URL}/archive`, title: t('header.archive')},
                {url: `${process.env.PUBLIC_URL}/projects`, title: t('header.projects')},
                {url: `${process.env.PUBLIC_URL}/phonebook`, title: t('header.phonebook')},
            ]
        } else {
            return []
        }

    }, [tokenData.parsedToken.type])

    const responsiveMegaBox1 = (megabox) => {
        if (megabox) {
            setMegaboxtoggle1(!megabox);
        } else {
            setMegaboxtoggle1(!megabox);
        }
    }

    const userType = useMemo(() => {
        switch (tokenData.parsedToken.type) {
            case 'admin':
                return t('header.userTypes.admin').toUpperCase()
            case 'moderator':
                return t('header.userTypes.moderator').toUpperCase()
            case 'technician':
                return t('header.userTypes.technician').toUpperCase()
            case 'accountant':
                return t('header.userTypes.accountant').toUpperCase()
            default:
                return tokenData.parsedToken.type.toUpperCase()
        }

    }, [tokenData.parsedToken.type])

    return (
        <Fragment>
            <div className="nav-right col-auto pull-right right-header p-0 ms-auto">
                <ul className="nav-menus">
                    {links.map((link, idx) => <li className="d-none d-lg-block" key={idx+'-'+link.title}><Link to={link.url}><span>{link.title}</span></Link></li>)}
                    <li className="profile-nav onhover-dropdown p-0 d-none d-lg-block">
                        <div className="media profile-media">
                            {/* <img className="b-r-10" src={"auth0_profile.picture"} alt="user-image" /> */}
                            <div className="media-body"><span>{tokenData.parsedToken.name}</span>
                                <p className="mb-0 font-roboto">{userType} <i className="middle fa fa-angle-down"></i></p>
                            </div>
                        </div>
                        <ul className="profile-dropdown onhover-show-div">
                            <li onClick={() => navigate('/profile')}><User /><span>{t('user.profile.title')}</span></li>
                            <li onClick={signOut}><LogIn /><span>{t('logout.title')}</span></li>
                        </ul>
                    </li>
                    <div className='d-block d-lg-none'>
                        <button className={'hamburger-icon nav-link'} onClick={() => setSideMenuOpen(!sideMenuOpen)}><Layers /></button>
                        <div className={(sideMenuOpen ? "d-block" : "d-none") + " mega-menu-container nav-submenu menu-to-be-close"}>
                            <Container fluid={true}>
                                <Row>
                                    <Col className="mega-box" onClick={() => responsiveMegaBox1(megaboxtoggle1)}>
                                        <div className="mobile-title d-none">
                                        <h5>{t('header.menu')}</h5><X onClick={() => setSideMenuOpen(false)} />
                                        </div>
                                    </Col>
                                    <Col className="mega-box" onClick={() => responsiveMegaBox1(megaboxtoggle1)}>
                                        <div className="link-section">
                                        <div className={`${megaboxtoggle1 ? "active" : ""}`}>
                                            <h6>{tokenData.parsedToken.name}</h6>
                                        </div>
                                        <ul className={`svg-icon ${megaboxtoggle1 ? "d-none" : ""}`}>
                                            <li>
                                                <div
                                                    className='liToA'
                                                    onClick={() => {
                                                        setSideMenuOpen(false)
                                                        navigate('/profile')
                                                    }}
                                                ><User />{t('user.profile.title')}
                                                </div>
                                            </li>
                                            <li><div onClick={signOut} className='liToA'><LogIn />{t('logout.title')}</div></li>
                                        </ul>
                                        </div>
                                    </Col>
                                    {links.map((link, idx) => (
                                        <Col key={idx+'-'+link.title} className="mega-box">
                                            <div className="link-section doted">
                                                <div><Link onClick={() => setSideMenuOpen(false)} to={link.url}><h6><span>{link.title}</span></h6></Link></div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </div>
                    </div>
                </ul>
            </div>
        </Fragment>
    )
}

export default Rightbar