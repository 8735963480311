import React, { /*useCallback, useState,*/ useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup } from 'reactstrap'
import { useRecoilValue } from 'recoil'

import { customStyles } from '../../../izUtils'
import { tokenState } from '../../../recoil/recoil'

const ClientList = ({data}) => {
    const { clientId } = useParams()
    const Navigate = useNavigate()

    const tokenData = useRecoilValue(tokenState);

    const handleRowClick = (state) => {
        if (tokenData.parsedToken.type === 'technician') {
            Navigate('/clients/' + state.id + '/get')
        } else {
            Navigate('/clients/' + state.id + '/patch')
        }
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            {
                name: t('table.client'),
                selector: row => row.client,
                sortable: true,
                cell: row => {
                    if (clientId === row.id) return <div className="clickableRow">{row.client}</div>
                    return row.client
                }
            },
            {
                name: t('table.location'),
                selector: row => row.location,
                sortable: true,
                cell: row => {
                    if (clientId === row.id) return <div className="clickableRow">{row.location}</div>
                    return row.location
                }
            },
        ]
    }, [clientId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default ClientList