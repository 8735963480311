import React, {useState, useEffect} from 'react'
import {t} from 'i18next'
import { FormGroup, Label, Button } from 'reactstrap'

import { isNull } from '../../../izUtils'
import DisplayHistory from '../../../helpers/DisplayHistory'
import { checkHistory } from '../../../helpers/checkHistory'

const TaxInput = ({data, onBlur, showErrors, history}) => {
    const { type, name, display, errors, title, disabled, mandatory} = data

    const [value, setvalue] = useState("")

    useEffect(() => {
        setvalue(data.value === null ? '' : data.value)
    }, [data.value])

    const handleChange = (e) => {
        setvalue(e.target.value)
    };

    const handleBlur = () => {
        if (!isNull(onBlur)) onBlur(value, name, false)
    }

    const checkTax = () => {
        if (!isNull(onBlur)) onBlur(value, name, true)
    }

    const addClientInputs = JSON.parse(localStorage.getItem('AKODA.clientAddInputs'));

    if (display) {
        let hasHistory = false;
        if (!isNull(history)) {
            hasHistory = checkHistory(data, history)
        }

        return (
            <FormGroup style={{ position: 'relative' }} className={hasHistory ? 'red-alert' : ''}>
                <Label>{title + (mandatory ? " *" : "")}</Label>
                <div className="d-flex align-items-start">
                    <input
                        className={"form-control"}
                        disabled={disabled}
                        type={type}
                        name={name}
                        placeholder={t(`form.placeholder.${name}`)}
                        onChange={handleChange}
                        value={value}
                        onBlur={handleBlur}
                    />
                    {addClientInputs?.client_type?.value === 'domestic_company' &&
                        <Button className="ms-3" onClick={checkTax}>{t('form.checkTax')}</Button>
                    }
                </div>
                {(!isNull(showErrors) && showErrors && !isNull(errors) && errors.length !== 0) && <div style={{ color: "red", marginBottom: "10px" }}>{errors.map((error, index) => <div key={"error-"+index}>{error}</div>)}</div>}
                {hasHistory && <DisplayHistory history={history} />}
            </FormGroup>
        );
    } else {
        return null;
    }
}

export default TaxInput