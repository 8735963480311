import React, { Fragment, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Button, Form, Card, CardBody, CardFooter, Row, Col } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import { headersState, tokenState } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer, linker } from '../../helpers/fields'
import Spinner from '../spinner/Spinner'
import { errorStatus } from '../../helpers/response'
import FileUpload from '../fileUpload/FileUpload'

const AddUpdateFacility = () => {
    const Navigate = useNavigate();
    const { clientId, facilityId } = useParams()

    const [headers] = useRecoilState(headersState);
    const tokenData = useRecoilValue(tokenState);
    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    const lsFacilityFiles = 'AKODA.facilityFiles';

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);
        updateData(false, { client: {value: clientId} });

        return () => {
            localStorage.removeItem(lsFacilityFiles)
        }

    }, [facilityId]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs, check_location, check_distance_time) => {
        let payload = { save, check_location, check_distance_time };
        if (!isNull(passedInputs)) {
            // Get data from inputs
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }

            // Get data from localStorage
            let facilityFiles = JSON.parse(localStorage.getItem(lsFacilityFiles))
            if (!isNull(facilityFiles)) {
                if (isNull(payload.data)) payload.data = {};
                payload.data.attachments = facilityFiles.map(file => file.id);
            }
        }

        let method = "patch";
        if (facilityId === 'create') method = "post";
        axios[method](env.api + '/api/client/facility/' + facilityId, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    Swal.fire({
                        title: t('saved'),
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    }).then(() => {
                        if (tokenData.parsedToken.type === 'technician') {
                            Navigate(-1)
                        } else {
                            Navigate('/clients/' + clientId + '/facilities/' + response.data.id)
                        }
                    })
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name, check_location, check_distance_time) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        if (name === 'billing_method' && value === 'hours') {
            clonedInputs['show_worklog_inspection'].value = true;
        }
        updateData(false, clonedInputs, check_location, check_distance_time);
    }

    // Create fields
    const mapLinker = (field, place) => {
        const inputlinkerFields = {
            field,
            inputs: place,
            showErrors,
            component: {id: 'createFacility'},
            textHandler: (value, id, checkLocation, checkDistance) => textChange(value, id, checkLocation, checkDistance),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip ribbon-primary">{(facilityId === 'create') ? t('facilities.create.title') : t('facilities.update.title')}</div>
                {isNull(inputs) ?
                    <Spinner />
                    :
                    <Fragment>
                        <Form className="theme-form">
                            <Row>
                                <Col lg="6">
                                    {[
                                        'client',
                                        'name',
                                        'facility_number',
                                        'location',
                                        'google_location',
                                        'country',
                                        'facility_category',
                                        'travel_expenses',
                                        'facility_mileage',
                                        'travel_time',
                                        'mandatory_service_verification',
                                        'archive_email',
                                    ].map(field => mapLinker(field, inputs)) }
                                </Col>
                                <Col lg="6">
                                    {[
                                        'supervisors',
                                        'facility_notes',
                                        'technician_expertise',
                                        'salesperson_expertise',
                                        'billing_method',
                                        'show_worklog_inspection',
                                    ].map(field => mapLinker(field, inputs)) }
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col lg="6">
                                    <FileUpload data={inputs.attachments} saveToLS={lsFacilityFiles} inputType="addFacility" />
                                </Col>
                            </Row>
                        </Form>
                    </Fragment>
                }
            </CardBody>
                <CardFooter>
                <div className='text-end'>
                    <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{(facilityId === 'create') ? t('add') : t('save')}</Button>
                </div>
            </CardFooter>
        </Card>
    )
}

export default AddUpdateFacility