import React, { Fragment, useState, useEffect } from 'react'
import {t} from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Button, Collapse, CardFooter } from 'reactstrap'
import { useRecoilState } from 'recoil'
import axios from 'axios'
import { Accordion } from 'react-bootstrap';

import env from '../../env/src_config'
import { headersState } from '../../recoil/recoil';
import Breadcrumbs from '../../layout/breadcrumb/Breadcrumb';
import Spinner from '../../components/spinner/Spinner'
import ClientList from '../../components/clients/lists/ClientList'
import PaginationTop from '../../components/Pagination/PaginationTop'
import PaginationBottom from '../../components/Pagination/PaginationBottom'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import Filter from '../../components/filters/Filter'
import { transformer } from '../../helpers/fields'
import AddUpdateClient from '../../components/clients/AddUpdateClient'

const Clients = () => {
    const Navigate = useNavigate();
    const [headers] = useRecoilState(headersState);
    const { clientId } = useParams();

    const [isOpen, setIsOpen] = useState(null);
    const [clients, setClients] = useState(null);
    const [filters, setFilters] = useState(null);

    const lsFilterName = 'AKODA.clientFilter';
    const filterFields = ['client', 'location', 'invoice_recipient']

    useEffect(() => {
        const lsFilter = JSON.parse(localStorage.getItem(lsFilterName));

        if (isNull(lsFilter)) {
            getClients({});
        } else {
            for (let filter in lsFilter) {
                if ( (filterFields.indexOf(filter) !== -1) && (!isNull(lsFilter[filter].value) && lsFilter[filter].value !== false && lsFilter[filter].value.length !== 0) ) {
                    setIsOpen(true)
                    break;
                }
            }

            getClients(lsFilter);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getClients = (passedFilters) => {
        const keys = Object.keys(passedFilters);
        let payload = { filters: {} };
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedFilters[keys[i]].value) && passedFilters[keys[i]].value.length !== 0) {
                payload.filters[keys[i]] = passedFilters[keys[i]].value;
            } else {
                payload.filters[keys[i]] = "";
            }
        }

        axios.post(env.api + '/api/client/list', payload, {headers}).then(response => {
            if (!isNull(response.data.data)) {
                setClients(response.data.data);
                if (!isNull(response.data.filters)) {
                    setFilters(transformer(response.data.filters));
                    localStorage.setItem(lsFilterName, JSON.stringify(transformer(response.data.filters)))
                }
            }
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const clearData = () => {
        localStorage.removeItem(lsFilterName);
        getClients({});
    }

    if (isNull(clients)) {
        return <Spinner />
    } else {
        return (
            <Fragment>
                <Breadcrumbs parent="" title={t('breadcrumb.clients')} />
                <Container fluid={true}>
                    <Row>
                        <Col md="6">
                            <Card className="ribbon-wrapper">
                                <CardBody>
                                    <div className="ribbon ribbon-clip ribbon-primary">{t('clients.list.title')}</div>
                                    <div>
                                        <Accordion className='mb-3'>
                                            <div className="default-according style-1" id="clientfilter">
                                                <Button color="secondary" className="acc-btn d-flex justify-content-between align-items-center" data-toggle="collapse" onClick={() => setIsOpen(!isOpen)}>
                                                    {t('filters')}
                                                    <div className={'filter-icon' + (isOpen ? ' rotate-icon' : '')}>
                                                        <i className="fa fa-chevron-down"></i>
                                                    </div>
                                                </Button>
                                                <Collapse isOpen={isOpen}>
                                                    <div className='p-3'>
                                                        <Filter filterFields={filterFields} filters={filters} getData={(clonedFilters) => getClients(clonedFilters)} lsFilterName={lsFilterName} clearData={clearData} />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Accordion>
                                        <PaginationTop filters={filters} getData={clonedFilters => getClients(clonedFilters)} />
                                        <ClientList data={clients} />
                                        <PaginationBottom filters={filters} getData={clonedFilters => getClients(clonedFilters)} />
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Button color="primary" className="mt-2 me-2" onClick={() => Navigate('/clients/create')}>{t('clients.list.addNew')}</Button>
                                </CardFooter>
                            </Card>
                        </Col>
                        {!isNull(clientId) &&
                            <Col md="6">
                                <AddUpdateClient getClients={() => getClients(filters)} />
                            </Col>
                        }
                    </Row>
                    <hr/>
                    <div className='d-flex flex-wrap justify-content-between mb-4'>
                        <div>
                            <Button color="danger" className="mt-2 me-2" onClick={() => Navigate('/')}>{t('back')}</Button>
                        </div>
                    </div>
                </Container>
            </Fragment>
        )
    }
}

export default Clients