import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {t} from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Button } from 'reactstrap'
import { useRecoilValue } from 'recoil'

import { customStyles } from '../../../../izUtils'
import { generateQR } from '../../../../helpers/generateQR'
import { tokenState } from '../../../../recoil/recoil'

const FacilityLists = ({data, headers}) => {
    const { clientId } = useParams()
    const Navigate = useNavigate()

    const tokenData = useRecoilValue(tokenState);

    const handleRowClick = (state) => {
        if (tokenData.parsedToken.type !== 'technician') {
            Navigate('/clients/' + clientId + '/facilities/' + state.id)
        }
    }

    // This loops through rows and creates them
    const tableColumns = useMemo(() => {
        return [
            {
                name: t('table.name'),
                selector: row => row.name,
                sortable: true,
            },
            {
                name: t('table.location'),
                selector: row => row.location,
                sortable: true,
            },
            {
                name: t('table.facility_number'),
                selector: row => row.facility_number,
                sortable: true,
            },
            {
                name: t('table.qrCode'),
                sortable: false,
                cell: row => {
                    return <Button color="primary" className="mt-2 me-2" onClick={() => generateQR(row.id, headers)}>QR</Button>
                }
            },
        ]
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <FormGroup>
            <DataTable
                columns={tableColumns}
                data={data}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
            />
        </FormGroup>
    )
}

export default FacilityLists