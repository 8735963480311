import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'
import { Button, Form, Card, CardBody, CardFooter } from 'reactstrap'
import Swal from 'sweetalert2'

import env from '../../env/src_config'
import { headersState } from '../../recoil/recoil'
import { axiosError } from '../../helpers/response'
import { isNull } from '../../izUtils'
import { transformer, linker } from '../../helpers/fields'
import Spinner from '../spinner/Spinner'
import { errorStatus } from '../../helpers/response'
import RelatedTasks from './RelatedTasks'

const AddUpdateException = ({ selectedUsers, setSelectedUsers }) => {
    const Navigate = useNavigate();
    const { exceptionId } = useParams()

    const headers = useRecoilValue(headersState);
    const [inputs, setInputs] = useState(null)
    const [relatedTasks, setRelatedTasks] = useState(null)
    const [futureExceptions, setFutureExceptions] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    useEffect(() => {
        if (!isNull(inputs)) setInputs(null)
        setShowErrors(false);
        updateData(false, null);

    }, [exceptionId]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateData = (save, passedInputs) => {
        let payload = { save, data: {} };
        if (!isNull(passedInputs)) {
            const keys = Object.keys(passedInputs);
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        let method = 'patch';
        if (exceptionId === 'create') {
            payload.data.technicians = selectedUsers;
            method = "post";
        }

        axios[method](env.api + '/api/user/exception/' + exceptionId /*+ (isNull(technicianId) ? '' : '/' + technicianId)*/, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'success') {
                    let title = t('schedule.exception.update.success.title');
                    if (exceptionId === 'create') title = t('schedule.exception.create.success.title');
                    Swal.fire({
                        title,
                        text: response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })
                    setSelectedUsers([]);
                } else {
                    setShowErrors(true)
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data));
            setRelatedTasks(response.data.technicians);
            setFutureExceptions(response.data.future_excpetions);
        }).catch(error => {
            axiosError(error, Navigate);
        });
    }

    const textChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        updateData(false, clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            futureExceptions,
            textHandler: (value, id) => textChange(value, id),
            selectHandler: (value, id) => textChange(value, id),
            booleanHandler: (value, id) => textChange(value, id),
            dateHandler: (value, id) => textChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    return (
        <Card className="ribbon-wrapper">
            <CardBody>
                <div className="ribbon ribbon-clip ribbon-primary">{(exceptionId === 'create' ? t('schedule.exception.create.title') : t('schedule.exception.update.title'))}</div>
                {isNull(inputs) ?
                    <Spinner />
                    :
                    <Form className="theme-form">
                        { ['exception', 'name', 'exception_from', 'exception_to', 'work_days', 'substitute_technician'].map(field => mapLinker(field)) }
                        <RelatedTasks relatedTasks={relatedTasks} />
                    </Form>
                }
            </CardBody>
            <CardFooter>
                <div className='text-end'>
                    <Button color="primary" className="mt-2 me-2" onClick={() => updateData(true, inputs)}>{(exceptionId === 'create') ? t('add') : t('save')}</Button>
                </div>
            </CardFooter>
        </Card>
    )
}

export default AddUpdateException