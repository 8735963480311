import React from "react";
import { Button, Input } from 'reactstrap'
import { t } from 'i18next'

import { isNull } from "../../../izUtils";

export const FilterComponentText = ({ filterValue, onFilter, onClear }) => (
    <div className="d-flex mb-1">
        <Input
            id="search"
            type="text"
            placeholder={t('form.placeholder.name')}
            value={filterValue}
            onChange={onFilter}
        />
        <Button color="primary" className="ps-3 pe-3 ms-2" onClick={onClear}><i className="fa fa-times"></i></Button>
    </div>
);

export const FilterComponentSelect = ({ options, filterValue, onFilter, onClear, type }) => {
    return (
        <div className="d-flex mb-1">
            <Input
                id="search"
                type="select"
                value={filterValue}
                onChange={onFilter}
            >
                <option key="default-option" value={""} style={{ fontWeight: '600' }}>{t(`table.${type}`)}</option>
                {!isNull(options) &&
                    options.map((option, index) => (
                        <option key={index} value={option.id}>{option.title}</option>
                    ))
                }
            </Input>
            <Button color="primary" className="ps-3 pe-3 ms-2" onClick={onClear}>
                <i className="fa fa-times"></i>
            </Button>
        </div>
    );
};