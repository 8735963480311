import React, { useEffect, useState } from 'react'
import {t} from 'i18next'
import GoogleMapReact from 'google-map-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import axios from 'axios';

const AnyReactComponent = ({ text }) => (
    <div><i className="fa fa-map-marker" style={{ fontSize: '20px' }}></i></div>
);

const Maps = ({coords, changeLocation}) => {
    const [selectedCoords, setSelectedCoords] = useState(null);
    const [address, setAddress] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const defaultProps = {
        center: {
            lat: 46.1203255,
            lng: 14.8156076
        },
        zoom: 9
    };

    useEffect(() => {
        if (coords) {
            let splitcords = coords.split(',')
            console.log(splitcords)

            setSelectedCoords({ lat: splitcords[0], lng: splitcords[1] });
        }
    }, [coords])

    const modaltoggle = () => setModalOpen(val => !val);

    const onMapClick = async (data) => {
        setSelectedCoords({ lat: data.lat, lng: data.lng });

        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.lat},${data.lng}&key=${"AIzaSyDuFlbOkjvnKO0jXZQ4JsJCl1EENulbPdo"}`);
            const results = response.data.results;

            if (results && results.length > 0) {
                setAddress(results[0].formatted_address);
            } else {
                setAddress('No address found');
            }

        } catch (error) {
            console.error('Error fetching address:', error);
            setAddress('Failed to retrieve address');
        }
    }

    const selectLocation = () => {
        let newCords = selectedCoords.lat + ',' + selectedCoords.lng;
        changeLocation(newCords)
        modaltoggle();
    }

    return (
        <div>
            <Button color="primary" className="btn-sm mt-2" onClick={modaltoggle}>{t('Google maps')}</Button>
            <Modal isOpen={modalOpen} toggle={modaltoggle} centered size="xl">
                <ModalHeader toggle={modaltoggle}>
                    {address ? address : <span>&nbsp;</span>}
                </ModalHeader>
                <ModalBody>
                    <div style={{ height: '600px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyDuFlbOkjvnKO0jXZQ4JsJCl1EENulbPdo' }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            onClick={onMapClick}
                        >
                            {selectedCoords &&
                                <AnyReactComponent
                                    lat={selectedCoords.lat}
                                    lng={selectedCoords.lng}
                                    text="My Marker"
                                />
                            }
                        </GoogleMapReact>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className='text-end'>
                        <Button color="primary" className='me-2' onClick={selectLocation}>{t('select')}</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default Maps