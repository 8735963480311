import { Col } from 'reactstrap'
import moment from 'moment';
import { isNull } from "../izUtils";
import TextInput from '../components/form/TextInput';
import NumberInput from '../components/form/NumberInput';
import TextArea from '../components/form/TextArea';
import Select from '../components/form/Select';
import DateInput from '../components/form/DateInput';
import MultiSelect from '../components/form/MultiSelect';
import Checkbox from '../components/form/Checkbox';
import Signature from '../components/form/custom/Signature';
import MaterialInput from '../components/form/custom/MaterialInput';
import SupervisorInput from '../components/form/custom/SupervisorInput';
import SSInspectionInput from '../components/form/custom/SSInspectionInput';
import AutocompleteInput from '../components/form/custom/AutocompleteInput';
import SelectSearch from '../components/form/custom/SelectSearch';
import ProjectMaterials from '../components/form/project/ProjectMaterials';
import TimeInput from '../components/form/TimeInput';
import LogMaterials from '../components/form/project/LogMaterials';
import ProjectTechnicians from '../components/form/project/ProjectTechnicians';
import ProjectLogs from '../components/form/project/ProjectLogs';
import TaxInput from '../components/form/custom/TaxInput';

export const linker = ({field, inputs, history, showErrors, futureExceptions, component, textHandler, selectHandler, dateHandler, booleanHandler, multiHandler, timeHandler, projectMaterialHandler, lsName}) => {
    if (inputs[field]) {
        let fieldHistory = null;
        if (!isNull(history)) {
            fieldHistory = {};
            fieldHistory.changed_at = history.changed_at;
            fieldHistory.changed_by= history.changed_by;
            fieldHistory.field= history.fields[field];
        }

        switch (inputs[field].type) {
            case 'text':
                return <TextInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} history={fieldHistory} component={component} />
            case 'password':
                return <TextInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} />
            case 'number':
                return <NumberInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} history={fieldHistory} />
            case 'integer':
                return <NumberInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} history={fieldHistory} />
            case 'textarea':
                return <TextArea key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} history={fieldHistory} />
            case 'select':
                return <Select key={'input-'+inputs[field].name} data={inputs[field]} onChange={selectHandler} showErrors={showErrors} history={fieldHistory} />
            case 'datetime':
                return <DateInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={dateHandler} showErrors={showErrors} futureExceptions={futureExceptions} showTime={true} history={fieldHistory} />
            case 'date':
                return <DateInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={dateHandler} showErrors={showErrors} futureExceptions={futureExceptions} history={fieldHistory} />
            case 'multiselect':
                return <MultiSelect key={'input-'+inputs[field].name} data={inputs[field]} onChange={multiHandler} showErrors={showErrors} component={component} history={fieldHistory} />
            case 'checkbox':
                return <Checkbox key={'input-'+inputs[field].name} data={inputs[field]} onChange={booleanHandler} showErrors={showErrors} component={component} history={fieldHistory} />
            case 'bool':
                return <Checkbox key={'input-'+inputs[field].name} data={inputs[field]} onChange={booleanHandler} showErrors={showErrors} component={component} history={fieldHistory} />
            case 'time':
                return <TimeInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={timeHandler} showErrors={showErrors} />
            case 'selectsearch':
                return <SelectSearch key={'input-'+inputs[field].name} data={inputs[field]} onChange={selectHandler} showErrors={showErrors} />
            // CUSTOM INPUTS
            case 'signature':
                return <Signature key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} showErrors={showErrors} history={fieldHistory} />
            case 'material':
                return <MaterialInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} history={fieldHistory} />
            case 'supervisors':
                return <SupervisorInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} />
            case 'security_system_inspections':
                return <SSInspectionInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} />
            case 'facility_search':
                return <AutocompleteInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={selectHandler} showErrors={showErrors} />
            case 'tax':
                return <TaxInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} showErrors={showErrors} />
            // PROJECT INPUTS - admin
            case 'project_materials':
                return <ProjectMaterials key={'input-'+inputs[field].name} data={inputs[field]} onChange={projectMaterialHandler} showErrors={showErrors} lsName={lsName} />
            case 'project_log_materials':
                return <ProjectMaterials key={'input-'+inputs[field].name} data={inputs[field]} onChange={projectMaterialHandler} showErrors={showErrors} />
            case 'project_logs':
                return <ProjectLogs key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} showErrors={showErrors} />
            // PROJECT INPUTS - technician
            case 'log_materials':
                return <LogMaterials key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} showErrors={showErrors} />
            case 'project_technicians':
                return <ProjectTechnicians key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} showErrors={showErrors} />
            default:
                console.log("Add new field type to 'linker' - " + inputs[field].type)
                return null
        }
    }
}

export const transformer = (fields) => {
    if (!isNull(fields)) {
        let data = {};
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].type === "multiselect" && fields[i].value === '') {
                fields[i].value = [];
                data[fields[i].name] = fields[i];
            } else if (fields[i].type === "date" && !isNull(fields[i].value) && fields[i].value.length !== 0) {
                fields[i].value = moment(fields[i].value).format('YYYY-MM-DD');
                data[fields[i].name] = fields[i];
            } else {
                data[fields[i].name] = fields[i];
            }
        }

        return data;
    }
}

export const transformerForPublic = (fields) => {
    if (!isNull(fields)) {
        let data = {};
        for (let i = 0; i < fields.length; i++) {
            fields[i].disabled = true;

            if (fields[i].type === "select") {
                fields[i].values = [{
                    id: fields[i].value.id,
                    title: fields[i].value.title,
                }];
                fields[i].value = fields[i].value.id;
            } else if (fields[i].type === "multiselect" || fields[i].type === "technicians") {
                if (fields[i].value === '') fields[i].value = [];
                fields[i].values = fields[i].value.map(val => val)
                fields[i].value = fields[i].value.map(val => val.id)
            } else if (fields[i].type === "files") {
                fields[i].values = fields[i].value
            }

            data[fields[i].name] = fields[i];
        }

        return data;
    }
}

export const linkerFilter = ({field, lsFilterName, inputs, col, textHandler, selectHandler, dateHandler, booleanHandler, autocompleteHandler}) => {
    let displayField = null;
    if (inputs[field]) {
        switch (inputs[field].type) {
            case 'text':
                displayField = <TextInput data={inputs[field]} onChange={textHandler} lsFilterName={lsFilterName} />
                break;
            case 'select':
                displayField = <Select data={inputs[field]} onChange={selectHandler} lsFilterName={lsFilterName} />
                break;
            case 'date':
                displayField = <DateInput data={inputs[field]} onChange={dateHandler} showTime={false} lsFilterName={lsFilterName} />
                break;
            case 'checkbox':
                displayField = <Checkbox data={inputs[field]} onChange={booleanHandler} lsFilterName={lsFilterName} />
                break;
            case 'facility_search':
                displayField = <AutocompleteInput data={inputs[field]} onChange={autocompleteHandler} />
                break;
            case 'selectsearch':
                displayField = <SelectSearch data={inputs[field]} onChange={selectHandler} lsFilterName={lsFilterName} />
                break;
            case 'number':
                displayField = <NumberInput data={inputs[field]} onChange={textHandler} lsFilterName={lsFilterName}  />
                break;
            case 'integer':
                displayField = <NumberInput data={inputs[field]} onChange={textHandler} lsFilterName={lsFilterName}  />
                break;
            default:
                console.log("Add new field type to 'linkerFilter' - " + inputs[field].type)
                return null;
        }

        if (inputs[field].display) {
            return <Col sm={col.sm} md={col.md} lg={col.lg} xl={col.xl} key={'input-'+inputs[field].name}>{displayField}</Col>
        }
    }
}